import React from 'react';
import classNames from 'classnames';
import {ProductThumbnail} from '@wix/wixstores-client-common-components/dist/src/ProductThumbnail/ProductThumbnail';
import {IMediaItem, MediaItemRequiredDimensions} from '@wix/wixstores-client-core/dist/src/types/media-item';
import * as imageSDK from 'image-client-api/dist/imageClientSDK';
import autobind from 'autobind-decorator';
import s from './ProductImage.scss';
import {HoverType, ImageModeId, ImageRatioId, IProduct} from '../../../types/types';

export interface IProductImageProps {
  product: IProduct;
  isMobile: boolean;
  hoverType: string;
  imageRatioId: number;
  imageModeId: number;
  classNames: {thumbnail: string; image: string};
  children?: React.ReactChild[] | React.ReactChild;
  inBrowser: boolean;
}

interface IProductImageState {
  shouldShowFakeImage: boolean;
  imageContainerDimensions: {width: number; height: number};
}

export enum DataHook {
  Images = 'product-item-images',
  PrimaryImage = 'product-item-primary-image',
  SecondaryImage = 'product-item-secondary-image',
}

export const ratioIdToRatioMap = {
  [ImageRatioId._3x2]: 3 / 2,
  [ImageRatioId._4x3]: 4 / 3,
  [ImageRatioId._1x1]: 1,
  [ImageRatioId._3x4]: 3 / 4,
  [ImageRatioId._2x3]: 2 / 3,
  [ImageRatioId._16x9]: 16 / 9,
  [ImageRatioId._9x16]: 9 / 16,
};

const ZOOM_COEFFICIENT = 1.3;

@autobind
export class ProductImage extends React.Component<IProductImageProps, IProductImageState> {
  private readonly imageContainerRef = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);
    this.state = {shouldShowFakeImage: true, imageContainerDimensions: {width: 0, height: 0}};
  }

  public componentDidMount(): void {
    this.updateImageContainerDimensions();
    setTimeout(() => this.setState({shouldShowFakeImage: false}), 1000);
  }

  public componentDidUpdate(prevProps: Readonly<IProductImageProps>): void {
    const {imageRatioId: prevImageRatioId} = prevProps;
    const {imageRatioId: currentImageRatioId} = this.props;

    if (prevImageRatioId !== currentImageRatioId) {
      this.updateImageContainerDimensions();
    }
  }

  public render() {
    const {imageRatioId} = this.props;
    const paddingTopRatioHack = `${100 / ratioIdToRatioMap[imageRatioId]}%`;

    return (
      <div
        className={s.productImages}
        ref={this.imageContainerRef}
        style={{paddingTop: paddingTopRatioHack, ...this.getSsrImagePlaceholder()}}
        data-hook={DataHook.Images}>
        {this.props.inBrowser && this.renderImages()}
        {this.props.children}
      </div>
    );
  }

  private updateImageContainerDimensions() {
    const width = Math.ceil(this.imageContainerRef.current.clientWidth);
    const height = Math.ceil(this.imageContainerRef.current.clientHeight);

    this.setState({imageContainerDimensions: {width, height}});
  }

  private getResizedImageUrl(
    {url: relativeUrl, width: sourceWidth, height: sourceHeight}: IMediaItem,
    {width: targetWidth, height: targetHeight}: MediaItemRequiredDimensions
  ): string {
    const {imageModeId} = this.props;

    if (sourceWidth <= targetWidth && sourceHeight <= targetHeight && imageModeId === ImageModeId.Fit) {
      return imageSDK.getScaleToFitImageURL(relativeUrl, sourceWidth, sourceHeight, sourceWidth, sourceHeight);
    } else if (imageModeId === ImageModeId.Fit) {
      return imageSDK.getScaleToFitImageURL(relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight);
    } else {
      return imageSDK.getScaleToFillImageURL(relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, {
        upscaleMethod: 'super',
      });
    }
  }

  private getSsrImagePlaceholder() {
    if (!this.state.shouldShowFakeImage) {
      return {};
    }

    const {
      imageModeId,
      imageRatioId,
      product: {media: medias},
    } = this.props;

    if (medias.length > 0) {
      const media = medias[0];
      const viewPort = {width: 100, height: 100};
      let newDimensions;

      if (imageModeId === ImageModeId.Fit) {
        const minMultiplier = Math.min(viewPort.width / media.width, viewPort.height / media.height);
        newDimensions = {width: media.width * minMultiplier, height: media.height * minMultiplier};
      } else {
        const ratio = ratioIdToRatioMap[imageRatioId];
        newDimensions = {width: viewPort.width, height: viewPort.width / ratio};
      }

      return {
        backgroundImage: `url(${this.getResizedImageUrl(media, newDimensions)})`,
        backgroundSize: imageModeId === ImageModeId.Crop ? 'cover' : 'contain',
      };
    } else {
      return {backgroundColor: 'rgba(0,0,0,.9)'};
    }
  }

  private renderImages() {
    const {
      hoverType,
      imageModeId,
      isMobile,
      product,
      classNames: {thumbnail: externalThumbnailClass, image: externalImageClass},
    } = this.props;

    const productImageWidth = this.state.imageContainerDimensions.width;
    const productImageHeight = this.state.imageContainerDimensions.height;
    const shouldRenderSecondaryImage = !isMobile && product.media.length >= 2 && hoverType === HoverType.Alternate;
    const coefficient = hoverType === HoverType.Zoom ? ZOOM_COEFFICIENT : 1;

    const imageClassNames = classNames(s.productImage, externalImageClass, {
      [s.crop]: imageModeId === ImageModeId.Crop,
      [s.fit]: imageModeId === ImageModeId.Fit,
    });

    const thumbnailClass = classNames(s.productThumbnail, externalThumbnailClass);

    return (
      <>
        <ProductThumbnail
          mediaItemIndex={0}
          width={productImageWidth * coefficient}
          height={productImageHeight * coefficient}
          className={thumbnailClass}
          imageClassName={imageClassNames}
          product={product}
          getResizedImageUrl={this.getResizedImageUrl}
          data-hook={DataHook.PrimaryImage}
          hasFixedSize={false}
          alwaysShowBadge={true}
          withSrcSet={false}
        />
        {shouldRenderSecondaryImage && (
          <ProductThumbnail
            mediaItemIndex={1}
            width={productImageWidth}
            height={productImageHeight}
            className={thumbnailClass}
            imageClassName={imageClassNames}
            product={product}
            getResizedImageUrl={this.getResizedImageUrl}
            data-hook={DataHook.SecondaryImage}
            hasFixedSize={false}
            alwaysShowBadge={true}
            withSrcSet={false}
          />
        )}
      </>
    );
  }
}
