import React from 'react';
import _ from 'lodash';
import imagesLoaded from 'imagesloaded';
import SlickSlider, {Settings as SlickSettings} from 'react-slick';
import classNames from 'classnames';
import './SliderGallery.st.css';
import './slick-carousel.global.scss';
import {keyboardEvents} from '../../constants';
import {NavArrowLeft, NavArrowRight} from '../../icons/dist';
import {IProvidedGlobalProps, withGlobalProps} from '../providers/GlobalPropsProvider';
import {ProductItem} from '../ProductItem/ProductItem';
import s from './SliderGallery.scss';
import {IPropsInjectedByViewerScript} from '../../types/slider-gallery-types';
import {Omit} from '@wix/native-components-infra/dist/src/types/types';
import {IGallerySantaProps, IProduct} from '../../types/types';
import autobind from 'autobind-decorator';
import {DataHook as ProductImagesDataHook} from '../ProductItem/ProductImage/ProductImage';
import {ProductPlaceholder} from './ProductPlaceholder/ProductPlaceholder';

export type SliderGalleryProps = Omit<
  IPropsInjectedByViewerScript & IGallerySantaProps,
  IProvidedGlobalProps['globals']
> &
  IProvidedGlobalProps;

export interface SliderGalleryState {
  width: number;
  height: number;
  currentActiveSlideIndex: number;
  inBrowser: boolean;
  navigationArrowPosition: number;
}

export interface DimensionsConfigUnit {
  num: number;
  unit: 'px' | '%';
}

interface IProductPlaceholder {
  id: string;
}

export function convertCssValueToConfig(cssValue: string): DimensionsConfigUnit {
  if (/^(\d+px)$/.test(cssValue)) {
    const n = /\d+/.exec(cssValue)[0];
    return {num: _.toInteger(n), unit: 'px'};
  } else if (/^(\d+\.\d+px)$/.test(cssValue)) {
    const n = /\d+\.\d+/.exec(cssValue)[0];
    return {num: _.toNumber(n), unit: 'px'};
  }
  return null;
}

export function convertConfigToNumber(dimensions: DimensionsConfigUnit, base: number): number {
  const {unit, num} = dimensions;
  if (unit === 'px') {
    return num;
  }
  if (unit === '%' && _.isNumber(num)) {
    return base * (num / 100);
  }
  return 0;
}

export const enum ArrowsDir {
  LEFT = 'left',
  RIGHT = 'right',
}

const NavigationArrow = ({dir}: {dir: ArrowsDir}) => {
  const p = {size: '22px'};
  switch (dir) {
    case ArrowsDir.LEFT:
      return <NavArrowLeft {...p} />;
    case ArrowsDir.RIGHT:
      return <NavArrowRight {...p} />;
  }
};

const NavigationControl = ({dir, onNav, arrowPosition}: {dir: ArrowsDir; onNav(): void; arrowPosition: number}) => {
  const onKeypressNavigation = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.keyCode === keyboardEvents.ENTER.keyCode) {
      onNav();
    }
  };

  return (
    <div className={classNames([s.navigationArrows, s[dir]])} style={{top: `${arrowPosition}px`}}>
      <button
        data-hook={`navigation-arrows-${dir}-button`}
        className={s.resetButton}
        type="button"
        onClick={() => onNav()}
        onKeyPress={onKeypressNavigation}>
        <NavigationArrow dir={dir} />
      </button>
    </div>
  );
};

const WrapWithNavigation = ({withNavigationArrows, children, onNavigate, arrowPosition}) => {
  return withNavigationArrows ? (
    <>
      <NavigationControl
        data-hook="navigation-arrows-left"
        onNav={() => onNavigate(ArrowsDir.LEFT)}
        dir={ArrowsDir.LEFT}
        arrowPosition={arrowPosition}
      />
      {children}
      <NavigationControl
        data-hook="navigation-arrows-right"
        onNav={() => onNavigate(ArrowsDir.RIGHT)}
        dir={ArrowsDir.RIGHT}
        arrowPosition={arrowPosition}
      />
    </>
  ) : (
    children
  );
};

@withGlobalProps
export class SliderGallery extends React.Component<SliderGalleryProps, SliderGalleryState> {
  private readonly productItemRefs = [];
  private _ref: HTMLDivElement;
  private _slickRef: SlickSlider;
  private imagesLoaded = false;

  constructor(props: SliderGalleryProps) {
    super(props);
    this.state = this.getInitialState();
  }

  private reportLoad() {
    const {globals} = this.props;
    if (globals.isInteractive && this.imagesLoaded) {
      globals.appLoadBI.loaded();
    }
  }

  public componentDidMount() {
    const {registerToComponentDidLayout} = this.props;
    registerToComponentDidLayout(this.reportAppLoaded);

    const {clientHeight, clientWidth} = this._ref.parentElement;
    this.setState(
      {
        height: clientHeight,
        width: clientWidth,
        inBrowser: true,
      },
      () => {
        this.updateNavigationArrowsPosition();

        imagesLoaded(document.querySelectorAll(`[data-hook="${ProductImagesDataHook.Images}"]`), () => {
          this.imagesLoaded = true;
          this.reportLoad();
          /* istanbul ignore next: hard to test it */
          this.props.globals.updateLayout && this.props.globals.updateLayout();
        });
      }
    );
  }

  public componentDidUpdate(prevProps: IProvidedGlobalProps) {
    if (
      _.get(prevProps, ['globals', 'isInteractive']) !== _.get(this.props, ['globals', 'isInteractive']) &&
      this.props.globals.isInteractive === true
    ) {
      this.reportLoad();
    }
  }

  private get shouldShowSlider(): boolean {
    return this.props.isLoaded;
  }

  private get isEmptyState(): boolean {
    const {products} = this.props.globals;
    return _.get(products, 'length', 0) === 0;
  }

  private get products(): IProduct[] | IProductPlaceholder[] {
    const {products} = this.props.globals;
    if (!this.isEmptyState) {
      return products;
    }
    return Array.from(new Array(this.slidesToShow)).map((__, index) => ({
      id: index.toString(),
    }));
  }

  public renderSlides(): JSX.Element {
    const {currentActiveSlideIndex, inBrowser, navigationArrowPosition} = this.state;
    const {styleParams} = this.props.globals;
    const onNavigate = (target: ArrowsDir | number) => {
      const newTarget = this.getNextThumbnailIndex(target);
      const {nextIndex, slickStrategy} = this.getNavigationData(newTarget, currentActiveSlideIndex);
      slickStrategy === 'next' ? this._slickRef.slickNext() : this._slickRef.slickPrev();
      this.setState({currentActiveSlideIndex: nextIndex});
    };
    const productSpacingInPx = `${styleParams.numbers.galleryMargin}px`;
    return (
      <WrapWithNavigation
        withNavigationArrows={inBrowser && !this.isEmptyState}
        onNavigate={onNavigate}
        arrowPosition={navigationArrowPosition}>
        <SlickSlider
          className={s.sliderGallerySlick}
          data-hook="slider-gallery-slick"
          {...this.getSlickSettings()}
          ref={slider => (this._slickRef = slider)}>
          {_.map(this.products, (product, i) => (
            <div key={product.id} className={s.sliderGallerySlideContainer}>
              <div
                data-hook="slider-gallery-slide"
                style={{
                  height: '100%',
                  paddingLeft: productSpacingInPx,
                  paddingRight: productSpacingInPx,
                }}>
                {this.isEmptyState ? (
                  <ProductPlaceholder />
                ) : (
                  <ProductItem
                    product={product as IProduct}
                    style={{height: '100%'}}
                    index={i}
                    inBrowser={inBrowser}
                    innerRef={c => (this.productItemRefs[i] = c)}
                  />
                )}
              </div>
            </div>
          ))}
        </SlickSlider>
      </WrapWithNavigation>
    );
  }

  private get slidesToShow(): number {
    const {
      globals: {
        styleParams: {
          numbers: {galleryColumns},
        },
        isMobile,
      },
    } = this.props;
    return isMobile ? 1 : galleryColumns;
  }

  public getSlickSettings(): SlickSettings {
    const slidesToShow = this.slidesToShow;
    return {
      arrows: false,
      dots: false,
      slidesToShow,
      slidesToScroll: 1,
      speed: 400,
      infinite: this.products.length > slidesToShow,
      accessibility: true,
      swipeToSlide: true,
      focusOnSelect: false,
    };
  }

  private getInitialState(): SliderGalleryState {
    const {
      globals: {
        dimensions: {height, width},
      },
    } = this.props;
    const widthConf = convertCssValueToConfig(s.sharedStyleVariables_galleryWidth);
    const heightConf = convertCssValueToConfig(s.sharedStyleVariables_galleryHeight);
    return {
      width: widthConf ? convertConfigToNumber(widthConf, width) : 0,
      height: heightConf ? convertConfigToNumber(heightConf, height) : 0,
      currentActiveSlideIndex: 0,
      inBrowser: false,
      navigationArrowPosition: 0,
    };
  }

  @autobind
  private reportAppLoaded() {
    /* istanbul ignore next: hard to test it */
    const {onAppLoaded, globals} = this.props;
    if (globals.isInteractive && typeof onAppLoaded === 'function') {
      onAppLoaded();
    }
  }

  private readonly getNextThumbnailIndex = targetIndex => {
    let nextIndex = targetIndex;
    if (targetIndex < 0) {
      nextIndex = this.products.length - 1;
    } else if (targetIndex === this.products.length) {
      nextIndex = 0;
    }
    return nextIndex;
  };

  private updateNavigationArrowsPosition() {
    const {inBrowser} = this.state;
    if (!inBrowser || this.isEmptyState) {
      return;
    }
    const imageContainerEl = document.querySelector(`[data-hook="${ProductImagesDataHook.Images}"]`);
    const arrowPosition = imageContainerEl.clientHeight / 2;
    this.setState({
      navigationArrowPosition: arrowPosition,
    });
  }

  private getNavigationData(
    target: ArrowsDir | number,
    currentIndex: number
  ): {slickStrategy: 'next' | 'prev'; nextIndex: number} {
    const op = target === ArrowsDir.LEFT ? _.subtract : _.add;
    const nextIndex = op(currentIndex, 1) % this.products.length;
    return {nextIndex, slickStrategy: target === ArrowsDir.RIGHT ? 'next' : 'prev'};
  }

  public render() {
    if (!this.shouldShowSlider) {
      return null;
    }
    const {globals} = this.props;

    const backgroundColor = _.get(globals.styleParams.colors, ['gallery_background', 'value'], 'transparent');
    const isFullWidth = globals.styleParams.booleans.full_width;

    return (
      <div data-hook="slider-gallery" style={{backgroundColor}} ref={r => (this._ref = r)} className={s.root}>
        <div data-hook="slides-container" className={classNames(s.slidesContainer, {[s.fullWidth]: isFullWidth})}>
          {this.renderSlides()}
        </div>
      </div>
    );
  }
}
